<template>
  <div>
    <Vertical :tituloPagina="tituloPagina" :layout="layoutType" :modoDes="modoDes" :visibleModo="visibleModo" :breadcrumb="breadcrumb">
      <slot/>
    </Vertical>
  </div>
</template>


<script>
import { layoutComputed } from "@/state/helpers"
import Vertical from "./vertical.vue"
import SistemaSrv from '@/services/SistemaSrv.js'
import SesionSrv from '@/services/SesionSrv.js'
import { todoMethods } from "@/state/helpers"
import { version } from '../../../package'

export default {
  props: {
    tituloPagina: {
      type: String,
      default: null
    },
    breadcrumb: {
      type: Array
    }
  },
  components: {
    Vertical,
  },
  data() {
    return {
      anioFecha: '',
      fecha: '',
      nombreEmpresa: '',
      segundosParaCierreDeSesion: 900,
      segundosParaCierreDeSesionInicial: 0,
      modoDes: '',
      visibleModo: false,
      version: version
    };
  },
  computed: {
    ...layoutComputed,
  },

  created() {
    var self = this

    self.modoDesarrollo()
    // Actualización del fondo de la aplicación cuando está dentro de la interfaz de usuario
    // var body = document.getElementsByTagName('body')[0]
    // body.classList.remove('gray-bg')

    // Cargas iniciales
    self.cargarFecha()
    self.cargarNomEmpresa()

    // Control de eventos
    // Escucha todos los eventos de cierre de sesión del sistema
    iu.bus.on('cerrar-sesion', self.cerrarSesion)

    /****************************************************************************
     * Control para cerrar la sesión del sistema en caso de no ser usado
     ****************************************************************************/
    // self.segundosParaCierreDeSesionInicial = self.segundosParaCierreDeSesion
    // window.document.body.addEventListener('click', function() {
    //   self.segundosParaCierreDeSesion = self.segundosParaCierreDeSesionInicial
    // })
    // setInterval(function() {
    //   if (self.segundosParaCierreDeSesion >= 5) {
    //     self.segundosParaCierreDeSesion -= 5
    //   } else iu.bus.$emit('cerrar-sesion')
    // }, 5000)
    /****************************************************************************
     * Fin de control para cerrar la sesión del sistema en caso de no ser usado
     ****************************************************************************/
     let moduloTmp = null;
    if(sessionStorage.getItem('argusblack.modulo') != null) {
      moduloTmp = sessionStorage.getItem('argusblack.modulo')
    } else {
      sessionStorage.setItem('argusblack.modulo', localStorage.getItem('argusblack.modulo'))
      moduloTmp = localStorage.getItem('argusblack.modulo')
    }
    
    if (moduloTmp != null) this.actualizarModuloSeleccionado({ modulo: moduloTmp })
  },
  methods: {
    ...todoMethods,
    cargarFecha: function() {
      var self = this

      if( sessionStorage.getItem('anioFecha') == null || 
          sessionStorage.getItem('anioFecha') == undefined ||
          sessionStorage.getItem('anioFecha') == ''
      ){
        SistemaSrv.fecha().then(response => {
          self.fecha = response.data

          let oFecha = new Date(self.fecha)
          self.anioFecha = oFecha.getFullYear()
          sessionStorage.setItem("anioFecha", oFecha.getFullYear())
        }).catch(error => {
          console.log('No se pudo cargar la fecha')
          console.log(error.response)
        })
      } else {
        self.anioFecha = sessionStorage.getItem('anioFecha')
      }
    },

    cargarNomEmpresa: function() {
      var self = this

      if( sessionStorage.getItem('nombreEmpresa') == null || 
          sessionStorage.getItem('nombreEmpresa') == undefined ||
          sessionStorage.getItem('nombreEmpresa') == ''
      ){
        SistemaSrv.nombreEmpresa().then(response => {
          self.nombreEmpresa = response.data
          self.cargarNombreEmpresa({ nombreEmpresa: response.data })
          sessionStorage.setItem("nombreEmpresa", response.data)
        })
      } else {
        self.cargarNombreEmpresa({
          nombreEmpresa: sessionStorage.getItem('nombreEmpresa')
        })
      }
    },

    cerrarSesion: function() {
      var self = this

      SesionSrv.cerrar()

      self.limpiarUsuario()

      self.$router.push({ name: 'login' })

      // Cerrar modals abiertos
      // $(document).ready(function(){
      //   $('.modal').modal('hide');
      // })
    },
    
    modoDesarrollo() {
      var self = this
      
      if( sessionStorage.getItem('modoDesarrollo') == null || 
          sessionStorage.getItem('modoDesarrollo') == undefined ||
          sessionStorage.getItem('modoDesarrollo') == ''
      ){
        SistemaSrv.modo().then(response => {
          self.modoDes = response.data
          if(self.modoDes != 'production') self.visibleModo = true
          else self.visibleModo = false

          if(self.modoDes == 'production') sessionStorage.setItem("modoDesarrollo", response.data)
        })
      } else {
        self.modoDes = sessionStorage.getItem('modoDesarrollo')
        if(self.modoDes != 'production') self.visibleModo = true
        else self.visibleModo = false
      }
    }
  },
  mounted() {
  },
};
</script>

<style>
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.cursor{
  cursor: pointer;
}
.dropdown-menu .primary:hover > i {
  color: #ffffff !important;
}
.dropdown-menu .primary:hover {
  color: white;
  background-color: #3B76E1;
}
.dropdown-menu .warning:hover > i {
  color: #ffffff !important;
}
.dropdown-menu .warning:hover {
  color: white;
  background-color: #F9C255;
}
.dropdown-menu .danger:hover > i {
  color: #ffffff !important;
}
.dropdown-menu .danger:hover {
  color: white;
  background-color: #F56E6E;
}
.dropdown-menu .success:hover > i {
  color: #ffffff !important;
}
.dropdown-menu .success:hover {
  color: white;
  background-color: #63AD6F;
}
.dropdown-menu .info:hover > i {
  color: #ffffff !important;
}
.dropdown-menu .info:hover {
  color: white;
  background-color: #77D4EF;
}
.dropdown-menu .secondary:hover > i {
  color: #ffffff !important;
}
.dropdown-menu .secondary:hover {
  color: white;
  background-color: #7f838b;
}

.dropdown-menu .dark:hover > i {
  color: #ffffff !important;
}
.dropdown-menu .dark:hover {
  color: white;
  background-color: #03273c;
}

.btn-light {
  border: 1px solid #e2e5e8;
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 0.765625rem;
  border-radius: 0.5rem;
}
</style>

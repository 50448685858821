<template>
  <!-- Notificaciones crear componente a parte-->
  <div class="dropdown d-inline-block">
    <button
      type="button"
      class="btn header-item noti-icon"
      id="page-header-notifications-dropdown-v"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      @click="actualizarNotificaciones()"
    >
      <eva-icon
        class="icon-sm"
        :style="modoTopbar == 'light' ? '' : 'color: #B1BCC7'"
        name="bell-outline"
      ></eva-icon>
      <span class="noti-dot bg-danger rounded-pill" v-show="n_notificaciones > 0">
        {{ n_notificaciones >= 99 ? '99' : n_notificaciones }}
      </span>
    </button>

    <div
      class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
      aria-labelledby="page-header-notifications-dropdown-v"
    >
      <div class="p-3">
        <div class="row align-items-center">
          <div class="col">
            <h5 class="m-0 font-size-15">
              Notificaciones
              <sup>({{ Intl.NumberFormat('es-MX').format( n_notificaciones ) }})</sup>
            </h5>
          </div>
        </div>
      </div>
      <SimpleBar data-simplebar style="max-height: 250px">
        <a 
          v-for="notificacion in notificaciones"
          href="javascript: void(0);"
          @click="visorNotificacion(notificacion.id)"
          class="text-reset notification-item"
          :key="notificacion.id"
        >
          <div class="d-flex">
            <div class="flex-shrink-0 me-3 auth-logo">
              <img
                :src="
                  `${API}/usuarios/${
                    notificacion.id_usuario_emisor
                  }/foto?_tk=${token}&ancho=50&alto=50`
                "
                class="rounded-circle avatar-sm"
                v-show="notificacion.existe_foto_usuario_emisor"
                v-if="notificacion.id_usuario_emisor != null"
              />
              <img
                class="rounded-circle avatar-sm"
                :src="usuarioicon" style="width: 30px"
                v-show="!notificacion.existe_foto_usuario_emisor"
                v-if="notificacion.id_usuario_emisor != null"
              />
              <img
                alt="image" class="rounded-circle avatar-sm auth-logo-dark"
                :src="argusicon" style="width: 30px"
                v-if="notificacion.id_usuario_emisor == null"
              />
              <img
                alt="image" class="rounded-circle avatar-sm auth-logo-light"
                :src="argusiconblanco" style="width: 30px"
                v-if="notificacion.id_usuario_emisor == null"
              />
            </div>
            <div class="flex-grow-1">
              <h6 class="mb-1">{{notificacion.asunto}} de {{notificacion.id_usuario_emisor != null ? notificacion.nombre_usuario_emisor : 'Sistema ArgusBlack'}}</h6>
              <div class="font-size-13 text-muted">
                <p class="mb-1">Fecha: {{formatoFecha(notificacion.created_at, 'dddd, DD/MM/YYYY')}}</p>
                <p class="mb-0">
                  <i class="mdi mdi-clock-outline"></i>&nbsp;
                  <span>{{tiempo(notificacion.created_at)}}</span>
                </p>
              </div>
            </div>
          </div>
        </a>
      </SimpleBar>
      <div class="p-2 border-top d-grid">
        <router-link 
          class="btn btn-sm btn-link font-size-14 btn-block text-center"
          :to="{ name: 'listadoNotificaciones' }"
        >
          <i class="uil-arrow-circle-right me-1"></i>
          <!-- <span>Ver todas las notificaciones ({{ Intl.NumberFormat('es-MX').format( n_notificaciones_leidas ) }})</span> -->
          <span>Ver todas las notificaciones</span>
        </router-link>
      </div>
    </div>
  </div>
  <!-- fin de notificaciones -->
</template>

<script>
import MensajeUsuarioSrv from '@/services/MensajeUsuarioSrv.js'
import usuarioicon from '@/assets/img/usuario.png'
import argusicon from '@/assets/img/logoArgus.png'
import argusiconblanco from '@/assets/img/logoArgusBlanco.png'
import moment from 'moment'
import API from '@/API.js'
import { SimpleBar } from "simplebar-vue3"
export default {
  name: 'Campana',
  components: {
    SimpleBar
  },
  data() {
    return {
      API: API,
      token: localStorage.getItem('argusblack.token'),
      usuarioicon: usuarioicon,
      argusicon: argusicon,
      argusiconblanco: argusiconblanco,
      notificaciones: [],
      n_notificaciones: parseInt(localStorage.getItem('argusblack.campana.n_notificaciones') || 0),
      n_notificaciones_leidas: 0,
      intervalNotificaciones: null
    }
  },
  computed: {
    usuario() {
      return this.$store.state.todo.usuario
    },
    modoTopbar: function() {
      return this.$store.state.layout.topbar
    },
  },
  beforeUnmount: function() {
    clearInterval(this.intervalNotificaciones)
  },
  mounted() {
    var self = this

    // self.n_notificaciones = 0 // Inicialización de la cantidad de notificaciones
    self.notificaciones = []

    // Carga inicial
    setTimeout(() => {
      self.actualizarNotificaciones()
    }, 1500)

    // Actualización periódica de las alertas
    self.intervalNotificaciones = setInterval(function() {
      self.actualizarNotificaciones()
    }, 60000)

    // Se manda a actualizar campana
    iu.bus.on('actualizarCampana', function() {
      self.actualizarNotificaciones()
    })
  },
  methods: {
    cantidadNoLeidos: function() {
      var self = this

      if(!self.usuario.id) return

      MensajeUsuarioSrv.cantidadNoLeidos({id_usuario_receptor: self.usuario.id}).then(response => {
        localStorage.setItem('argusblack.campana.n_notificaciones', response.data)
        self.n_notificaciones = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se a podido optener la cantidad de notificaciones"
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(()=> {
        iu.bus.emit('actualizarNNotificaciones', self.n_notificaciones)
      })
    },
    cantidad: function() {
      var self = this

      if(!self.usuario.id) return

      let params = {
        id_usuario_receptor: self.usuario.id,
        vistos: 1
      }

      MensajeUsuarioSrv.cantidad(params).then(response => {
        self.n_notificaciones_leidas = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se a podido optener la cantidad de notificaciones"
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    tiempo: function(mt) {
      return moment(mt).locale('es-mx').fromNow()
    },
    formatoFecha(mt, formato = 'DD/MM/YYYY') {
      return moment(mt).locale('es-mx').format(formato)
    },
    actualizarNotificaciones: function() {
      var self = this

      if(!self.usuario.id) return

      let params = {
        id_usuario_receptor: self.usuario.id,
        limite: 8,
        vistos: 0
      }

      MensajeUsuarioSrv.mensajesJSON(params).then(response => {
        self.notificaciones = response.data

        self.cantidadNoLeidos()
        self.cantidad()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los mensajes de usuarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    visorNotificacion: function (idMensaje) {
      MensajeUsuarioSrv.marcarMensajeVisto(idMensaje).then(response => {
        this.$router.push({
          name: 'visorNotificacion',
          params: {
            id: idMensaje
          }
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se a podido marcar el mensaje como leido"
        }
        swal("Error", mensaje, "error");
        console.log(error)
      }).finally(() => {
        this.actualizarNotificaciones()
        iu.bus.emit('mensajeActualizar', idMensaje)
      })
    }
  }
}
</script>